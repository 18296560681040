import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TaxSlabs from "../components/tax-slabs"

function TaxSlabs2024() {
    return (
        <Layout>
            <TaxSlabs></TaxSlabs>
        </Layout>
    );
}
export const Head = () => <Seo title="Tax Slabs FY 2024-2025 | Tax Calculator Pakistan" description="The following slabs and income tax rates will be applicable for salaried persons for the years 2024-2025 according to the new budget." />

export default TaxSlabs2024;